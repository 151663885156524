/* @link https://utopia.fyi/type/calculator?c=320,16,1.618,1140,32,1.618,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  font-size: 16px !important;
}

html {
  font-size: 16px !important;
  scroll-behavior: smooth;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border-radius: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

*,
body {
  font-family: "Montserrat", sans-serif;
}

.title {
  font-weight: 700;
  line-height: 1.2em;
}
.title--lg {
  font-size: clamp(2.62rem, 1.6rem + 5.11vw, 5.24rem);
}
.title--md {
  font-size: clamp(1.62rem, 0.99rem + 3.16vw, 3.24rem);
}
.title--sm {
  font-size: clamp(1rem, 0.61rem + 1.95vw, 2rem);
}

.text {
  font-weight: 500;
  line-height: 1.2em;
}
.text--lg {
  font-size: clamp(1rem, 0.61rem + 1.95vw, 2rem);
}
.text--md {
  font-size: clamp(1.12rem, 0.38rem + 1.21vw, 0.62rem);
}
.text--sm {
  font-size: clamp(0.9rem, 0.5rem + 0.75vw, 1rem);
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.letterSpacing {
  letter-spacing: 2px;
}

.bold {
  font-weight: 700;
}

.extraBold {
  font-weight: 900;
}

.paddingBottom {
  padding-bottom: 0.2rem;
}

.button {
  font-size: clamp(0.9rem, 0.5rem + 0.75vw, 1rem);
  letter-spacing: 2px;
  padding: 10px 30px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
}
.button--primary {
  background: #fdfdfd;
  color: #212121;
}
.button--secondary {
  background: #0a2351;
  color: #fdfdfd;
}
.button--margin {
  margin: 1rem 0;
}
.button--animated {
  letter-spacing: 2px;
  transition: 0.3s;
}
.button--animated:hover {
  letter-spacing: 6px;
}
.button--centered {
  margin: 0 auto;
}

.button--testimonials {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
}
.button--testimonials img {
  max-width: 30px;
}

.showcase {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100vh;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #212121;
  transition: 0.5s;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .showcase {
    padding: 50px;
  }
}
.showcase--active {
  right: 270px;
}
.showcase__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 30px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .showcase__header {
    padding: 50px;
  }
}
.showcase__logo {
  color: #fdfdfd;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  cursor: pointer;
}
.showcase__toggle {
  position: relative;
  width: 60px;
  height: 60px;
  background: url(https://i.ibb.co/HrfVRcx/menu.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
}
.showcase__toggle--active {
  background: url(https://i.ibb.co/rt3HybH/close.png);
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  cursor: pointer;
}
.showcase__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.8;
}
.showcase__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0a2351;
  mix-blend-mode: overlay;
}
.showcase__text {
  max-width: 100%;
  position: relative;
  z-index: 10;
  color: #fdfdfd;
}
@media screen and (min-width: 768px) {
  .showcase__text p {
    max-width: 50%;
  }
}
.showcase__social {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcase__social-item {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showcase__social-icon {
  display: inline-block;
  margin-right: 20px;
  transform: scale(0.5);
  transition: 0.5s;
}
.showcase__social-icon:hover {
  transform: scale(0.5) translateY(-15px);
}

.card {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .card {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .card:nth-child(even) {
    flex-direction: row-reverse;
  }
}
.card__text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media screen and (min-width: 768px) {
  .card__text {
    width: 50%;
  }
}
.card__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .card__buttons {
    flex-direction: row;
  }
}
.card__technologies {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.card__tags {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.card__image-container {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .card__image-container {
    max-width: 50%;
  }
}
.card__image {
  border-radius: 5px;
  max-width: 100%;
}

.testimonials {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
.testimonials__container {
  max-width: 80%;
}
.testimonials__text {
  margin-bottom: 1.5rem;
}
.testimonials__avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.testimonials__image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.testimonials__buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.timeline {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timeline__item {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .timeline__item {
    max-width: 650px;
  }
}
.timeline__item-graphics {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.timeline__item-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.timeline__item-circle img {
  width: 30px;
}
.timeline__item-line {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timeline__item-line img {
  width: 2px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.timeline__item-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subtitle {
  padding: 30px;
}
@media screen and (min-width: 768px) {
  .subtitle {
    padding: 50px;
  }
}
.subtitle--section {
  background-color: #fcfcfc;
  color: #212121;
}
.subtitle--accents {
  background-color: #0a2351;
  color: #fdfdfd;
}

.skills {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media screen and (min-width: 768px) {
  .skills {
    gap: 5rem;
    flex-direction: row;
    justify-content: center;
  }
}
.skills__container--row {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
@media screen and (min-width: 768px) {
  .skills__container--row {
    gap: 5rem;
  }
}
.skills__container--col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.skills__items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.skills__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.skills__item img {
  width: 30px;
}
.skills__text {
  max-width: 55ch;
}

.navbar {
  background-color: #fcfcfc;
  position: absolute;
  top: 0;
  right: 0;
  width: 270px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar__menu {
  text-align: center;
  position: relative;
}
.navbar__menu-item {
  font-size: clamp(1.12rem, 0.38rem + 1.21vw, 0.62rem);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  list-style: none;
  padding: 1rem 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .container {
    flex-direction: row;
  }
}

.footer {
  margin-top: 100vh;
  background-color: #0a2351;
  color: #fdfdfd;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  padding-top: 30px;
}
.footer__contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.footer__icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.footer__icons-item {
  display: inline-block;
  transition: 0.5s;
}
.footer__icons-item:hover {
  transform: scale(1.1) translateY(-10px);
}
.footer__icons-item img {
  width: 50px;
  height: 50px;
}
.footer__copyright {
  border-top: 1px solid #fdfdfd;
  padding: 20px;
}

.loading {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #040e22;
  color: white;
  position: absolute;
  z-index: 999999 !important;
}

.app__main {
  min-height: 100vh;
}

.home__main {
  position: relative;
  top: 100vh;
}
.home__cards {
  background-color: #fcfcfc;
  padding: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
@media screen and (min-width: 768px) {
  .home__cards {
    gap: 4rem;
    padding: 0 50px 50px 50px;
  }
}
.home__cards-more {
  background-color: #fcfcfc;
  padding: 0 30px 30px 30px;
}
@media screen and (min-width: 768px) {
  .home__cards-more {
    padding: 0 50px 30px 50px;
  }
}
.home__testimonials {
  background-color: #fcfcfc;
  padding: 0 30px 60px 30px;
}
@media screen and (min-width: 768px) {
  .home__testimonials {
    padding: 30px 300px 100px 300px;
  }
}
.home__timeline {
  background-color: #0a2351;
  color: #fdfdfd;
  padding: 0 30px 30px 30px;
}
@media screen and (min-width: 768px) {
  .home__timeline {
    padding: 0 300px 100px 300px;
  }
}
.home__skills {
  background-color: #fcfcfc;
  padding: 0 30px 30px 30px;
}
@media screen and (min-width: 768px) {
  .home__skills {
    padding: 0 50px 100px 50px;
  }
}